import MaxWidth from 'components/functional/maxWidth'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const PerformanceSchedualStyles = styled.section`
  width: 100%;
  background-color: var(--main);
  padding: 3rem 0;
  text-align: center;
  svg {
    height: 75px;
    width: 75px;
    @media ${media.lg} {
      width: 100px;
      height: 100px;
    }
    @media ${media.xl} {
      width: 110px;
      height: 110px;
    }
  }
  .gatsby-image-wrapper {
    width: 125px;
    @media ${media.md} {
      width: 150px;
    }
  }
  h3 {
    font-size: 2rem;
    margin-top: 0.25rem;
    @media ${media.lg} {
      font-size: 2.5rem;
    }
  }
  > p {
    margin-top: 0.5rem;
  }
  > strong {
    display: block;
    color: var(--white);
    font-size: 1.5rem;
    margin-top: 2.5rem;
    @media ${media.lg} {
      font-size: 2.5rem;
    }
  }
  .wrapper {
    max-width: 800px;
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    strong {
      color: var(--mainYellow);
      font-size: 1.5rem;
    }
    p {
      width: 80%;
      margin: var(--auto);
      margin-top: 0.5rem;
    }
    @media ${media.md} {
      width: 100%;
      margin: 3rem auto 0 auto;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0;
      strong {
        font-size: 1.85rem;
      }
    }
    @media ${media.lg} {
      strong {
        font-size: 2rem;
      }
      p {
        font-size: 1.3rem;
      }
    }
  }
`
const PerformanceSchedual = () => {
  const i = true
  return (
    <MaxWidth $main>
      <PerformanceSchedualStyles>
        <StaticImage
          src="../../images/icons/Clock.png"
          alt=""
          placeholder="none"
        />
        <h3>PERFORMANCE SCHEDULE</h3>
        <p>(Running Time - approximately 2hr 20mins)</p>
        <div className="wrapper">
          <section>
            <strong>MATINEES</strong>
            <p>Tuesday, Thursday &amp; Saturday at 3.00pm</p>
          </section>
          <section>
            <strong>EVENINGS</strong>
            <p>Monday to Saturday at 7.30pm</p>
          </section>
        </div>
      </PerformanceSchedualStyles>
    </MaxWidth>
  )
}

export default PerformanceSchedual
