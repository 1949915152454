import MaxWidth from 'components/functional/maxWidth'
import CovidUpdate from 'components/global/covidUpdate'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const TicketsStyles = styled.section`
  width: 100%;
  padding: 6rem 0 3rem 0;
  @media ${media.md} {
    padding: 5rem 0 3rem 0;
  }
  h1 {
    text-align: center;
    @media ${media.md} {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    @media ${media.xl} {
      margin-bottom: 3rem;
      margin-top: 3rem;
    }
  }
  h2,
  p {
    text-align: center;
  }
  h2 {
    text-transform: uppercase;
    margin: 1rem 0;
    span {
      color: var(--main);
    }
    @media ${media.md} {
      font-size: 2rem;
    }
    @media ${media.xl} {
      font-size: 2.5rem;
    }
  }
  p {
    margin: var(--auto);
    max-width: 400px;
  }
  @media ${media.md} {
    pading-bottom: 1rem;
  }
`

const Info = styled.section`
  text-align: center;
  margin-top: 1.5rem;
  h4 {
    color: var(--main);
  }
  p {
    margin: var(--auto);
    max-width: 900px;
  }
`
const TicketsHeader = () => {
  const i = true
  return (
    <MaxWidth $black>
      <TicketsStyles>
        <h1>Tickets</h1>
        <h2>
          <span>Book direct.</span> Book with confidence.
        </h2>
        <p>
          Pay no booking fee with no quibble exchanges up to 24 hours before
          performances as standard.
        </p>
        {/*<Info>*/}
        {/*  <h4>Sat 24th December time change</h4>*/}

        {/*  <p>Due to national rail strikes on the day, please be advised the performance will now take place at the earlier time of 1.30pm on Sat 24th December and will therefore end at the earlier time of 3.50pm.</p>*/}
        {/*  <p>&nbsp;</p>*/}

        {/*  <p><strong>What do I need to do?</strong></p>*/}

        {/*  <p>If you are still able to attend at 1.30pm, please reply to the email you should have received regarding the updated time, confirming receipt and that you will attend the performance at 1.30pm.</p>*/}

        {/*  <p>If you cannot attend at the new time and you wish to exchange to another date, either respond to the email you should have received regarding the updated time with your preferred date and time, or ring us on 020 7836 1443 and we will do our very best to accommodate for you.</p>*/}

        {/*  <p>If you would like to move the value of the booking to credit to use at a different time, respond to the email with  “Please credit me” and we will send you further details. For anything else please ring the Box Office on 020 7836 1443.</p>*/}
        {/*  <p>&nbsp;</p>*/}

        {/*  <p><strong>What will happen next?</strong></p>*/}

        {/*  <p>You will receive new PDF tickets with the updated time within the next 24 hours.</p>*/}

        {/*  <p>On 23rd December you will receive a text (if a mobile number was provided) and email confirming the new start time of 1.30pm.</p>*/}

        {/*  <p>On 24th December, 5 hours ahead of the performance, tickets will be issued via text or email.</p>*/}
        {/*</Info>*/}
        {/* <CovidUpdate $tickets /> */}
      </TicketsStyles>
    </MaxWidth>
  )
}

export default TicketsHeader