import Layout from 'components/global/layout'
import Buttons from 'components/index/buttons'
import PageHeader from 'components/shared/pageHeader'
import PerformanceSchedual from 'components/tickets/performanceSchedual'
import TicketPrices from 'components/tickets/ticketPrices'
import TicketsHeader from 'components/tickets/ticketsHeader'
import { graphql } from 'gatsby'
import React from 'react'

const Tickets = () => {
  const meta = {
    title: 'Tickets | The Mousetrap',
  }
  return (
    <Layout title={meta.title}>
      <TicketsHeader />
      <Buttons />
      <PerformanceSchedual />
      <TicketPrices />
    </Layout>
  )
}

export default Tickets
